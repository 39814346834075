const MenuData = {"menu": {
  "breakfast_sandwich": [
    ["Egg",2.49],
    ["Egg & Cheese",3.75],
    ["Bacon, Egg & Cheese",5.99],
    ["Ham, Egg & Cheese",5.99],
    ["Sausage, Egg & Cheese",5.99],
    ["Kielbasa, Egg & Cheese",5.99],
    ["Pepper, Egg & Cheese",4.75],
    ["Western",6.99],
    ["Steak, Egg & Cheese",8.99],
    ["Pastrami, Egg & Cheese",6.99],
    ["Turkey, Egg & Cheese",6.99]
  ],
  "breakfast_assortment":  [
    ["Danish & Turnovers",2.50],
    ["Assorted Muffins",1.99],
    ["Assorted Bagels",1.00],
    ["-        with butter",1.75],
    ["-        with cream cheese",2.99],
    ["Butter Roll",1.25],
    ["-        with peanut butter",1.50],
    ["-        add banana",1.00],
    ["Croissant",1.00],
    ["-        with butter",1.25],
    ["Donuts",1.45]
  ],
  "hot_menu": [
    ["Meatloaf",7.99,15.50],
    ["Chicken Cutlet",7.99,15.50],
    ["Grilled Chicken",7.99,15.50],
    ["Buffalo Chicken",7.99,15.50],
    ["Teriyaki Chicken",7.99,15.50],
    ["Steak & Cheese",7.99,15.50],
    ["Meatball & Cheese",7.99,15.50],
    ["Pot Roast",7.99,15.50],
    ["Pulled Pork",7.99,15.50]
  ],
  "cold_menu": [
    ["B.L.T.",6.75,12.99,null],
    ["Bologna",7.50,14.50,4.69],
    ["Capicola",7.50,14.50,8.99],
    ["Chicken Breast",7.50,14.50,9.29],
    ["Cranberry, Walnut & Chic Salad",7.50,14.50,null],
    ["Egg Salad",6.99,14.50,null],
    ["Genoa Salami",7.50,14.50,8.29],
    ["Domestic Ham",6.50,12.50,4.29],
    ["Honey Ham",7.50,14.50,9.99],
    ["Honey Turkey",7.50,14.50,9.99],
    ["Imported Ham",7.50,14.50,8.99],
    ["Liverwurst",6.99,13.50,6.29],
    ["Pastrami",7.75,14.99,11.99],
    ["Pepperoni",7.50,14.50,8.29],
    ["Roast Beef",7.99,15.50,13.49],
    ["Seafood Salad",7.50,14.50,null],
    ["Tuna",7.25,13.99,null],
    ["Turkey",7.50,14.50,8.99],
    ["Black Pepper Turkey",7.50,14.50,9.99],
    ["Veal Loaf",6.75,12.99,6.29],
    ["Veggie",6.75,12.99,null]
  ],
  "breakfast_specials": [
    ["Slim's Revenge","Kielbasa, egg and cheddar on wrap with hash brown and sour cream", 7.50, null, null],
    ["Alicia's Bagel", "Ham, bacon, egg and cheese on bagel", 7.25, null, null],
    ["Meatlover", "Ham, bacon, sausage, egg and cheese", 8.50, null, null],
    ["Davey B", "Ham, egg and cheese with peppers and hot sauce", 6.50, null, null],
    ["Rise and Shine", "Egg, pepper jack cheese, roasted and jalapeno peppers, with onion, salsa, hot sauce and refried beans", 6.50, null, "With steak for  ...  $8.99"],
    ["Steaker", "Steak, cheddar, and egg on bagel", 8.99, null, null],
    ["Farmhouse", "Sausage, egg, cheddar, jalapeno and hash brown", 7.50, null, null],
    ["Rigger Wrap", "Egg, double kielbasa and Swiss on wrap", 7.99, null, null],
    ["Brian's Rigger Wrap", "Ham, egg, cheddar, swiss, provolone and hash brown", 7.99, null, null],
    ["Pete's Pick", "Double bacon, double egg and double cheese on wrap", 8.25, null, null],
    ["Barnyard", "Ham, bacon, sausage, egg, provolone, and hash brown on wrap", 9.50, null, null],
    ["Eastern", "Kielbasa, egg, peppers, onion and cheese", 7.25, null, null],
    ["JFK", "Pastrami, egg, swiss cheese and honey dijon", 6.99, null, null],
    ["The Yogi", "Sausage, bacon, egg, cheddar cheese, hash brown, hot sauce on cheddar bagel", 8.25, null, null],
    ["The Mountainman", "Chicken cutlet, egg, pepperjack, bacon, BBQ sauce", 9.50, null, null],
    ["Jim McMuffin", "Ham, egg, cheddar, hash brown on an English muffin", 6.99, null, null],
    ["The Veg", "Spinach, red onion, roasted pepper, jack cheese, hot sauce on a wrap", 5.99, null, null]
  ],
  "lunch_specials": [
    ["Rocking Roast Beef","Roast beef, horse radish cheese, lettuce, tomato, onion, banana peppers and horse radish sauce", 7.99, 15.50, null, 'cold'],
    ["Kickin Chickin","Buffalo chicken salad, cheese, bacon, lettuce, tomato, bleu cheese", 9.25, 17.99, null, 'both'],
    ["Jake","Honey ham, honey turkey, cheese, lettuce, bacon, banana peppers and honey mustard", 8.75, 16.99, null, 'cold'],
    ["The Trucker","Chicken teriyaki, cheddar, onion, lettuce, tomato, ranch and sour cream", 7.99, 15.50, null, 'hot'],
    ["Billy Club","Chicken cutlet, pepper jack cheese, banana pepper, lettuce, tomatoes, bacon and honey mustard", 9.25, 17.99, null, 'hot'],
    ["Steve's Tasty Tuna","Tuna, cheddar, lettuce, onion, jalapeno, bacon, honey mustard", 8.75, 16.99, null, 'cold'],
    ["Bedell Wrap","Buffalo chicken, pepper jack, honey mustard, roasted peppers, jalapeno peppers, and banana peppers, lettuce", 7.99, 15.50, null, 'hot'],
    ["Spicy Italian","Ham, salami, capicola, pepperoni, provolone, lettuce, tomato, onion, jalapenos, hot sauce and horse radish sauce", 7.99, 15.50, null, 'cold'],
    ["Dave's #9","Steak & cheese, peppers, mushrooms and onions", 7.99, 15.50, null, 'hot'],
    ["Ragin Cajun","Black pepper turkey, horseradish cheese, lettuce, tomato, onion, banana and roasted peppers, mayo and bacon, salt and pepper", 8.75, 16.99, null, 'cold'],
    ["Joe's Meat","Meatloaf, provolone, jalapenos, ketchup and horseradish", 7.99, 15.50, null, 'hot'],
    ["Cobuzzi","Chicken, teriyaki, cheese, lettuce, onion and ranch", 7.99, 15.50, null, 'hot'],
    ["Jim's Italian Combo","Ham, salami, capicola, pepperoni, provolone, lettuce, tomato, peppers, onion, olives, mayo, oil/vinegar, salt/pepper and oregano", 7.99, 15.50, null, 'cold'],
    ["All American","Ham, turkey, swiss, lettuce, tomato, onion, banana peppers, oil/vinegar and peppercorn dressing", 7.50, 14.50, null, 'cold'],
    ["Belly Buster","Ham, turkey, salami, pepperoni, bacon, lettuce, tomato, pepper jack cheese, onion, peppers, oil/vinegar and peppercorn dressing", 9.25, 17.99, null, 'cold'],
    ["Cordon Bleu Club","Chicken cutlet topped with ham, provolone, bacon, lettuce and ranch dressing", 9.50, 18.50, "No Bacon ... 7.75    15.50", 'hot'],
    ["Roast Beefer","Roast beef with melted swiss, mushrooms, lettuce, tomato and mayo", 7.99, 15.50, null, 'hot'],
    ["Spanky's Firefighter","Grilled chicken with peppers, onions, garlic and olice oil with cheese", 7.99, 15.50, null, 'hot'],
    ["Timmy T","Meatball, pepperoni, provolone with peppers and onions", 8.50, 16.50, null, 'hot'],
    ["Cuban","Pulled pork with ham, swiss, pickles, spicy mustard and mayo", 8.25, 15.99, null, 'hot'],
    ["Gonzadu","Prosciutto, capicola, provolone, lettuce, tomato, roasted red peppers, oil/vinegar and oregano", 7.99, 15.50, null, 'cold'],
    ["Greek","Salami, onions and peppers topped with feta cheese, lettuce, tomato, oil/vinegar and oregano", 7.50, 14.50, null, 'both'],
    ["The 'Hammer'", "Steak with cheddar, bacon, onions and BBQ sauce", 9.25, 17.99, null, 'hot'],
    ["Max 'D'","Honey turkey, salami, pepperoni, lettuce, tomato, pepper jack, onion, red roasted peppers, bacon, garlic salt and pepper with chipolte sauce", 9.25, 17.99, null, 'cold'],
    ["Cameron","Deli chicken breast with provolone, onion, red roasted peppers, lettuce and chipolte sauce", 7.50, 14.50, null, 'both'],
    ["Ray's Scotch","Turkey, american cheese, onion, red roasted peppers, sauerkraut and spicy mustard", 7.50, 14.50, null, 'both'],
    ["Harvey Banger","Honey ham, honey turkey, cheddar, lettuce, tomato, red onion, cucumbers, raw peppers and honey Dijon", 7.50, 14.50, null, 'cold'],
    ["The Mistake","Pot roast, swiss, ham, mayo, spicy mustard with banana peppers", 8.99, 17.50, null, 'hot'],
    ["Ted's Blazing","Buffalo sliced chicken breast, pepper jack cheese, banana and jalepeno peppers, lettuce, tomato, red onion and chipolte mayo", 7.50, 14.50, null, 'both'],
    ["Mavani Veggie","Pepper jack cheese, (onion, jalapeno, roasted peppers, banana peppers, plives, cucumbers, pickles, tomato), with lettuce and ranch", 6.75, 12.99, null, 'cold'],
    ["Spencer","Oven roasted turkey with italian cheese, bacon, onion, banana peppers and ranch dressing", 8.99, 17.50, null, 'cold'],
    ["Big Mac (meatloaf)","Cheddar, lettuce, tomato, onion, pickle, thousand island dressing", 7.99, 15.50, null, 'hot'],
    ["Pat's Special","Eggplant, mozzarella, red roasted peppers, lettuce, tomato, mayo and oil/vinegar", 6.75, 12.99, null, 'hot'],
    ["Julies Italian","Ham, salami, capicola, pepperoni, italian cheese, lettuce, tomato, red onion, red peppers, olives, mayo and oil/vinegar", 7.99, 15.50, null, 'cold'],
    ["Packed Pastrami","Cheddar, red onions, durkee onions, bacon and coleslaw", 9.25, 17.99, null, 'hot'],
    ["Ultimate BLT","Honey turkey, cheddar, lettuce, tomato, mayo and bacon", 8.75, 16.99, null, 'hot'],
    ["Crabby Gabby","Steak, cheddar, banana peppers, bacon, lettuce, tomato, mayo and BBQ sauce", 9.25, 17.99, null, 'hot'],
    ["Timmy P","Chicken cutlet, sauce, italian cheese, oil, spices, pepperoni", 8.50, 16.50, null, 'hot'],
    ["The Yogi","Deli chicken breast, feta cheese, spinach, red onion, tomato, spices, oil/vinegar on a wrap", 7.50, 14.50, null, 'cold'],
    ["Suzi Q","Chicken cutlet, mozzarella cheese, garlic, red roasted peppers, spinach, tomato, spices & italian dressing", 7.99, 15.50, null, 'hot'],
    ["Air Connection","Buffalo chicken, cheddar, bacon, lettuce, tomato, ranch, hot sauce", 9.25, 17.99, null, 'hot'],
    ["Shay Z","Chicken cutlet, jack cheese, jalapenos, red & green peppers, onions, bacon & ranch",  9.25, 17.99, null, 'hot'],
    ["“Mott” Zarella","Chicken cutlet, mozzarella, sauce, bacon, garlic, spices, oregano, oil & grated cheese",  9.25, 17.99, null, 'hot'],
    ["The Wayside","Pulled pork, cheddar, coleslaw, macaroni and cheese on wrap", 8.99, 17.50, null, 'hot'],
    ["Quick Lane","Chicken cutlet, provolone, BBQ sauce, red roasted peppers", 7.99, 15.50, null, 'hot'],
    ["Acme Monaco","Deli chicken breast, prosciutto, mozzarella, italian seasoning, light oil/vinegar, hot sauce, red roasted peppers & tomato", 7.75, 14.99, null, 'both'],
    ["The I.T.","Honey ham, provolone, lettuce, tomato, red onion, pickles & peppercorn dressing", 7.50, 14.50, null, 'cold'],
    ["Claudia’s Combo","Roast beef, turkey, swiss, banana peppers, coleslaw & thousand island on ciabatta", 7.99, 15.50, null, 'both'],
    ["Jules","Grilled chicken, feta, spinach, tomato, red onion, raw peppers, cucumber, honey mustard, hummus", 7.99, 15.50, null, 'both'],
    ["Zakk","Chicken cutlet, prosciutto, mozzarella, oil, oregano, banana peppers, light sauce", 8.99, 17.50, null, 'hot'],
    ["Kerry","Roast beef, swiss, red roasted peppers, garlic spread, oil & spices", 7.99, 15.50, null, 'hot'],
    ["Wawa","Oven roasted turkey, durkee onion, bacon, cheese, mayo", 9.25, 17.99, null, 'cold'],
    ["Chicken Ceasar Wrap","Crispy or grilled chicken, lettuce, spinach, croutons, tomato, olives, ceasar dressing", 8.50, null, null, 'cold'],
    ["Chicken Parmesan","Crispy chicken cutlet, mozzarella, sauce, oil, spices", 7.99, 15.50, null, 'hot'],
    ["Monte Cristo Wrap","Honey ham and turkey, swiss, thousand island on french toast", 7.50, null, null, 'hot'],
    ["Lucette","Steak, horseradish cheese, onion, jalapenos, banana peppers, mayo, hot sauce", 7.99, 15.50, null, 'hot'],
    ["Harley","Tuna, italian cheese, tomato, cucumbers, mushroom", 7.25, 13.99, null, 'cold'],
    ["Gopher","Pulled pork, roast beef, lettuce, tomato, swiss, onion, banana peppers, mayo, horseradish mayo", 8.50, 16.50, null, 'hot'],
    ["Megaliscious","Honey ham, cheddar, lettuce, tomato, red onion, red roasted peppers, spices, honey dijon", 7.50, 14.50, null, 'both'],
    ["Pastrami Rueben","Pastrami, swiss, sauerkraut, thoasand island dressing", 7.99, 15.50, null, 'hot'],
    ["Saucy Complainer","Cranberry chicken walnut salad, lettuce, durkee onion, bbq, horseradish mayo", 7.50, 14.50, null, 'cold'],
    ["New Yorker","Roast beef, swiss, bacon, lettuce, tomato, red onion, spicy mustard, horseradish", 9.25, 17.99, null, 'both'],
    ["Rachel's Ham","Ham, lettuce, tomato, pickles, red onion, red roasted, cheese, mayo, oil", 7.50, 14.50, null, 'cold'],
    ["Black Bean Burger","Jack cheese, spinach, tomato, red onion, red roasted, chipotle", 6.99, 13.50, null, 'hot'],
    ["Scarletti","Grilled chicken, mozz, garlic, red onion, lettuce, tomato, balsamic", 7.99, 15.50, null, 'hot']
  ],
  "tossed_salads": [
    ["Tossed", 5.99, 8.99],
    ["Chicken Tossed", 8.99, 11.99],
    ["Buffalo Chicken", 8.99, 11.99],
    ["Chicken Teriyaki", 8.99, 11.99],
    ["Chef", 8.99, 11.99]
  ],
  "side_salads": [
    ["Macaroni", 6.99],
    ["Potato (All)", 6.99],
    ["Tortellini", 6.99],
    ["Pasta Salad", 5.99],
    ["Four Bean", 5.29],
    ["Rice Pudding", 5.99],
    ["Mushrooms", 6.99],
    ["Tuna", 8.49],
    ["Coleslaw", 5.79],
    ["Apple Crisp", 7.99],
    ["Mozzarella", 7.99],
    ["Cucumber Salad", 7.99],
    ["Buffalo Chicken", 10.49],
    ["Cranberry Walnut Chicken", 10.49],
    ["Chicken Salad", 8.99],
    ["Egg Salad", 5.99],
    ["Ham Salad", 5.49],
    ["Seafood Salad", 8.99],
    ["Cutlet", 3.50],
    ["Fruit Cups", 6.99],
    ["Stuffed Prosciutto Peppers", 9.99]
  ],
  "side_snacks": [
    ["Nuts (All)", 10.29],
    ["Swedish Fish", 6.99],
    ["Trail Mix", 9.99],
    ["Lettuce", 2.69],
    ["Tomato, raw peppers, onions", 2.99],
    ["Lettuce", 2.69],
    ["Potatoes", 1.00]
  ],
  "single_snacks": [
    ["Pickle", 1.25],
    ["Lemon / Limes", 1.50],
    ["Cucumbers", 2.00],
    ["Package Wraps", 6.99]
  ],
  "cheeses": [
    ["Land O'Lakes American", 6.29],
    ["Cheddar", 6.99],
    ["Horseradish", 10.19],
    ["Italian Sharp", 8.99],
    ["Provolone", 6.99],
    ["Swiss", 7.69],
    ["Pepper Jack", 6.99],
  ]
}};

export default MenuData;