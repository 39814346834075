



export default function NotFound() {

  return (
    <>
      <div>
        <div>
          404 Page Not Found
        </div>
      </div>
    </>
  )
}