export default function Slideshow() {

  return (

    
    <div id="body2" className="bodyObject">
      <div id="carouselFiller"></div>
      
    </div>
  )

};